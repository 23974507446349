<template>
  <main>
    <Row :gutter="15">
      <Col :span="10">
      <ButtonGroup shape="circle">
        <Button icon="md-refresh" @click="loadData">刷新</Button>
      </ButtonGroup>
      </Col>
      <Col :span="7" class="text-right">
      <DatePicker type="daterange" placeholder="激活时间范围" @on-change="filterDate" style="width: 250px"></DatePicker>
      </Col>
      <Col :span="7">
      <searcher ref="searcher" :options="searches" @on-change="loadData"></searcher>
      </Col>
    </Row>

    <Divider />

    <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>

    <Divider />
    <pagination ref="pagination" :total="total" @on-change="loadData" />


  </main>
</template>

<script>

export default {
  data() {
    return {

      searches: {
        user_id: "分享用户ID",
        activated_user_id: "激活用户ID",
        member_card_online_id: '会员卡ID'
      },
      sort: "",
      total: 0,
      data: [],
      columns: [
        {
          title: "ID",
          key: "id",
          sortable: "custom",
          align: "center",
          minWidth: 80
        },
        {
          title: "会员卡标题",
          key: "card",
          sortable: "custom",
          align: "center",
          minWidth: 130,
          render: (h, params) => {
            return h("p", params.row.card.title);
          }
        },
        {
          title: "分享用户",
          key: "user",
          sortable: "custom",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            return h(
              "a",
              {
                class: "flex flex-align-center",
                on: {
                  click: () => {
                    this.$router.push("/user/detail/" + params.row.user.id);
                  }
                }
              },
              [
                h("img", {
                  attrs: {
                    src: params.row.user.avatar
                      ? params.row.user.avatar
                      : require("@/assets/no-avatar.jpg"),
                    width: '48',
                    height: '48'
                  },
                  class: "avatar mr"
                }),
                h("p", params.row.user.nickname)
              ]
            );
          }
        },
        {
          title: "激活用户",
          key: "activated_user",
          sortable: "custom",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            return h(
              "a",
              {
                class: "flex flex-align-center",
                on: {
                  click: () => {
                    this.$router.push("/user/detail/" + params.row.activated_user.id);
                  }
                }
              },
              [
                h("img", {
                  attrs: {
                    src: params.row.activated_user.avatar
                      ? params.row.activated_user.avatar
                      : require("@/assets/no-avatar.jpg"),
                    width: '48',
                    height: '48'
                  },
                  class: "avatar mr"
                }),
                h("p", params.row.activated_user.nickname)
              ]
            );
          }
        },

        {
          title: "预估佣金",
          minWidth: 120,
          key: "commission",
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h(
              "p",
              { class: "text-price" },
              params.row.commission
            );
          }
        },
        {
          title: "激活时间",
          minWidth: 140,
          key: "created_at",
          align: "center",
          sortable: "custom"
        },
        {
          title: "佣金结算时间",
          minWidth: 140,
          key: "settlement_at",
          align: "center",
          sortable: "custom"
        },
        {
          title: "订单状态",
          key: "status",
          sortable: "custom",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            switch (params.row.status) {
              case -1:
                return h("Tag", { props: { color: "error" } }, "已取消");

              case 0:
                return h("Tag", { props: { color: "primary" } }, "待结算");

              case 1:
                return h("Tag", { props: { color: "success" } }, "已结算");

              default:
                return h("Tag", "-");
            }
          }
        },
        {
          title: "操作",
          minWidth: 200,
          align: "center",
          render: (h, params) => {
            var html = [];

            if (
              this.Auth(["MemberCardOnlineShare", "cancel"]) &&
              params.row.status === 0
            ) {
              html.push(
                h(
                  "Button",
                  {
                    props: {
                      type: "error",
                      size: "small"
                    },
                    class: "mg-lr-xs",
                    on: {
                      click: () => {
                        this.cancel(params.row);
                      }
                    }
                  },
                  "取消"
                )
              );
            }

            return h("div", html);
          }
        }

      ]
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let params = {

        sort: this.sort,
        search_key: this.$refs.searcher.search_key,
        keywords: this.$refs.searcher.keywords,
        begin_date: this.begin_date,
        end_date: this.end_date,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit
      };

      this.$http.get("/memberCardOnlineShare/list", { params: params }).then(res => {
        this.total = res.total;
        this.data = res.data;
      });
    },
    filterDate(date) {
      this.begin_date = date[0];
      this.end_date = date[1];
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    changeSort(item) {
      this.sort = item.order == "normal" ? "" : item.key + "," + item.order;
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    cancel(item) {
      this.$Modal.confirm({
        title: "确定要取消该用户的分享佣金吗？",
        content: "确定要取消" + item.user.nickname + "的分享佣金吗？",
        onOk: () => {
          this.$http.post("/memberCardOnlineShare/cancel/id/" + item.id).then(res => {
            this.loadData();
          });
        }
      });
    },
  }
};
</script>